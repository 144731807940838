<template>
<div>
<v-dialog
    v-model="activo"
    width="950"
    :persistent="activo"
    scrollable
  >
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">Listas variables</div>
        <!-- Boton Agregar nueva Promoción -->
        <v-btn
          class="ml-4"
          color="success"
          x-small
          fab
          title="Nueva Lista Variable"
          @click="nuevaEditarListaVariable(null, true)"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3 px-4" :class="pantallaChica ? '': 'mb-2'" :style="pantallaChica ? 'height: 140px;' : (pantallaMediana ? '' : 'height: 60px;')">
        <v-form @submit.prevent="buscar()"class="">
          <v-row class="pa-0 mt-0">
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-text-field
                label="Nombre"
                v-model="nombre"
                tabindex="1"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col  cols="12" sm="4" md="4" class="py-0">
              <v-switch
                v-model="inhabilitado"
                label="Inhabilitado"
                class="ma-0 pt-2"
                tabindex="1"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <BtnFiltroVue
                :loading="load"
                @action="buscar()"
                clase="ma-0 pa-0"
                @clear="limpiar()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="pt-3">
        <!-- DATA TABLE -->
        <v-row class="">
          <v-col cols="12" class="px-0 pt-0">
            <v-data-table 
              class="cebra mt-2 elevation-1"
              :headers="header"
              :items="listasVariables"
              :loading="load"
              :search="search"
              :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
              dense
            >
              <!-- Busqueda -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pt-0 pl-3 pr-3 pb-3" no-gutters>
                  <v-col cols="6" sm="3" md="2">
                    <SearchDataTableVue
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para mostrar
                </v-alert>
              </template>
              <template v-slot:[`item.acciones`]="{item}">
                <div class="d-flex">
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                      <v-btn @click="nuevaEditarListaVariable(item, false)" icon small>
                        <v-icon
                          class="px-2"
                          v-on="on"
                          color="orange"
                          small
                        >
                          fas fa-pen
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> Editar Lista </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.inhabilitado == 0">
                    <template v-slot:activator="{ on }">
                      <v-btn 
                        @click="inhabilitarListaVariable(item)" 
                        icon small
                      >
                        <v-icon
                          class="px-2"
                          v-on="on"
                          color="error"
                          small
                        >
                          fas fa-ban
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> Inhabilitar Lista </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.porcentaje`]={item}>
                {{item.porcentaje}} %
              </template>
            </v-data-table> 
          </v-col>
        </v-row>
      </v-card-text>
      <ModalAgregarEditarListaVariableVue 
        v-model="objModal.activo"
        :datos="objModal"
        @guardarCambios="guardarCambios"
      />
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import moment from 'moment';
import ModalAgregarEditarListaVariableVue from './ModalAgregarEditarListaVariable.vue';
import BtnConfirmarVue from '../../util/BtnConfirmar.vue';
import BtnFiltroVue from '../../util/BtnFiltro.vue';
import SearchDataTableVue from '../../util/SearchDataTable.vue';

export default {
  name: 'ModalListasVariables',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    activo:{
      get(){
        return this.datos.dialog_list_var;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  data(){
    return{
      moment: moment,
      //variable formulario
      nombre: null,
      inhabilitado: 0,
      //Variable al init del formulario
      sucursales: this.$store.state.sucursales,
      dias: [],
      //Variable para Nuevo y editar
      objModal: {
        activo: false,
        nuevo: false,
        dias: [],
        sucursales: this.sucursales,
        //cuando es uno que ya existe
        listaVariable: {
          dias: [],
          id_lista_var: null,
          inhabilitado: null,
          nombre_lista_var: null,
          porcentaje: null,
          sucursal_codigo: null,
          sucursal_nombre: null,
        },
      },
      //Variables para el data-table
      header: [
        { text: 'Id', value: 'id_lista_var', align: 'end' },
        { text: 'Descripción', value: 'nombre_lista_var' },
        { text: 'Porcentaje', value: 'porcentaje',align: 'end' },
        { text: 'Sucursal', value: 'sucursal_nombre' },
        { text: 'Días', value: 'dias' },
        { text: 'Acción', value: 'acciones', align: 'center', sorteable: false, filtreable: false,},
      ],
      listasVariables:[],
      load: false,
      search: '', 
      //Variables para controlar los inhabilitados
      mostrarBoton: true,
      //variables para controlar el actualizar dias
      diasActuales: [],
      diasNvosModal: [],
      diasParaAgregar: [],
      diasParaEliminar: [],
      pantallaChica: this.$vuetify.breakpoint.xs,
      pantallaMediana: this.$vuetify.breakpoint.sm,
    }
  },
  created(){
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      const res1 = await this.$store.dispatch('listas/getListasVariables', { nombre: '', inhabilitado: 0 })
      const res2 = await this.$store.dispatch('listas/getDias')
      this.$store.state.loading = false;

      if(res1.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res1.message, color: 'error', })
      }
      if(res2.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res2.message, color: 'error', })
      }
      this.listasVariables = res1.listas_variables
      this.dias = res2.dias;
    },
    async buscar(){
      //this.mostrarBoton = false;
      this.listasVariables = [];
      this.search = '';
      this.load = true;
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('listas/getListasVariables', {
        nombre: this.nombre? this.nombre.toUpperCase() : '', 
        inhabilitado: this.inhabilitado == null || this.inhabilitado == 0? 0 : this.inhabilitado 
      })
      this.$store.state.loading = false;
      this.load = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.listasVariables = res.listas_variables;
    },
    limpiar(){
      this.nombre = null;
      this.inhabilitado = 0;
      this.search = '';
      this.load = false;
      this.diasNvosModal = [];
      this.diasActuales = [];
      this.diasParaAgregar = [];
      this.diasParaEliminar = [];
    },
    nuevaEditarListaVariable(item, nuevo){
      if(nuevo){ //solo si es nuevo
        item = {
          dias: [], //si es nuevo va vacío, si existe se reemplaza en el modal
          id_lista_var: 0, //indica que es nuevo
          inhabilitado: 0,
          nombre_lista_var: null,
          porcentaje: null,
          sucursal_codigo: null,
          sucursal_nombre: null,
        }
        this.objModal.listaVariable = item;
      }
      this.objModal.nuevo = nuevo
      this.objModal.listaVariable = item;
      this.objModal.activo = true;
      this.objModal.sucursales = this.sucursales;
      this.objModal.dias = this.dias;
    },
    async guardarCambios(lista, nuevo){
      if(!nuevo){
        this.diasNvosModal = [];
        this.diasNvosModal = lista.dias;
        lista.dias = this.controlarDias();
      }

      this.$store.state.loading = true;
      const res = await this.$store.dispatch( nuevo ? 'listas/newListaVariable' : 'listas/actualizarListaVariable' , lista)
      this.$store.state.loading = false;

      if(res.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
      }
      this.$swal.fire({
        icon: 'success',
        title: nuevo ? '¡Lista variable grabada!' : '¡Lista variable modificada!',
        text: nuevo ? 'Se creó correctamente la lista variable: '+lista.nombre_lista_var : 'Se modificó correctamente la lista variable: '+lista.nombre_lista_var,
        timer: 3000
      });
      if(nuevo){
        this.listasVariables.push(lista.dias.join(", "))
      }else{
        let pos = this.listasVariables.map(e => e.id_lista_var).indexOf(lista.id_lista_var);
        let diasAux = this.diasNvosModal.map(e => e.nombre_dia).join(", ")
        lista.dias = diasAux;

        if(pos != -1){
          this.listasVariables[pos].dias              = lista.dias
          this.listasVariables[pos].id_lista_var      = lista.id_lista_var
          this.listasVariables[pos].inhabilitado      = lista.inhabilitado
          this.listasVariables[pos].nombre_lista_var  = lista.nombre_lista_var
          this.listasVariables[pos].porcentaje        = lista.porcentaje
          this.listasVariables[pos].sucursal_codigo   = lista.sucursal_codigo
          this.listasVariables[pos].sucursal_nombre   = lista.sucursal_nombre 
          //Object.assign(this.listasVariables[pos], lista);
        }
      }
      this.objModal.activo = false;
    },
    controlarDias(){
      this.diasParaAgregar = [];
      this.diasParaEliminar = [];
      let auxDias = this.objModal.listaVariable.dias.split(", "); //dias que ahora están en el data-table
      auxDias.forEach(element => {
        let pos = this.dias.map(e => e.nombre_dia).indexOf(element)
        if(pos != -1){
          this.diasActuales.push( this.dias[pos] ); //creo el array de objetos de la forma {id_dia:, nombre_dia:, abreviacion:,} con los días actuales
        }
      });
      let n = this.diasNvosModal.length;
      for(let i = 0; i < n;i++){
        let pos = this.diasActuales.map(e => e.id_dia).indexOf(this.diasNvosModal[i].id_dia)
        if(pos == -1){
          this.diasParaAgregar.push(this.diasNvosModal[i])
        }
      }
      n = this.diasActuales.length;
      for(let i = 0; i < n;i++){
        let pos = this.diasNvosModal.map(e => e.id_dia).indexOf(this.diasActuales[i].id_dia)
        if(pos == -1){
          this.diasParaEliminar.push(this.diasActuales[i])
        }
      }
      this.diasActuales = [];
      return ({
        diasParaAgregar: this.diasParaAgregar,
        diasParaEliminar: this.diasParaEliminar
      })
    },
    async inhabilitarListaVariable(item){
      this.$swal.fire({
        icon: 'warning',
        title: `Está por inhabilitar la lista variable: ${item.nombre_lista_var} . ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true;
          const res = await this.$store.dispatch('listas/anularListaVariable', { id_lista_var: item.id_lista_var })
          this.$store.state.loading = false;

          if(res.resultado == 0){ return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', }); }

          this.$swal.fire({
            icon: 'success',
            title: 'Lista Variable Inhabilitada',
            text: 'Se inhabilitó la lista variable '+item.nombre_lista_var+' con éxito.',
            timer: 3000
          });
          let pos = this.listasVariables.map(e => e.id_lista_var).indexOf(item.id_lista_var);
          if(pos != -1){
            this.listasVariables[pos].inhabilitado = 1;
            //this.mostrarBoton = true;
          }
        }
      })
    }
  },
  components:{
    BtnConfirmarVue,
    BtnFiltroVue, SearchDataTableVue,
    ModalAgregarEditarListaVariableVue
  },
  watch:{
    activo: function(val){
      if(!val){
        this.limpiar();
      }else this.init();
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
    '$vuetify.breakpoint.sm'(val){
      this.pantallaMediana = val;
    },
  }
}
</script>

<style>

</style>